import { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useAtomValue } from "jotai";
import { useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { PartnerListItem } from "@sellernote/_shared/src/types/forwarding/partner";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  checkIsBlNumberByBLType,
  checkIsSettlement,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import useSnackbar from "../../../../../hooks/useSnackbar";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../jotaiStates/auth";
import ArrivalNoticeSubmit from "./ArrivalNoticeSubmit";
import BasicDescription from "./BasicDescription";
import ChangeStatus from "./ChangeStatus";
import MoveToFinishedStatusConfirmModal from "./MoveToFinishedStatusConfirmModal";
import RejectSettlementModal from "./RejectSettlementModal";
import UpdateBLNumberAndLiner from "./UpdateBLNumberAndLiner";

const BasicInfo = ({
  trelloDetail,
  partnerList,
}: {
  trelloDetail: TrelloBidDetail;
  partnerList?: PartnerListItem[];
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const [showRejectSettlementModal, setShowRejectSettlementModal] =
    useState(false);

  const [showsConfirmModal, setShowsConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  const {
    mutate: moveTrelloToFinishedStatus,
    ResponseHandler: ResponseHandlerOfMoveTrelloToFinishedStatus,
  } = TRELLO_BID_QUERY.useMoveTrelloToFinishedStatus({
    bidId: trelloDetail.id,
    onSuccess: () => {
      setShowsConfirmModal(false);
      setConfirmMessage("");
      handleSnackbarOpen("종료 단계로 변경되었습니다.");
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
    },
  });

  const {
    mutate: moveExportShipmentToFinished,
    ResponseHandler: ResponseHandlerOfMoveExportShipmentToFinished,
  } = TRELLO_BID_QUERY.useMoveExportShipmentToFinished({
    shipmentId: trelloDetail.id,
    onSuccess: () => {
      setShowsConfirmModal(false);
      setConfirmMessage("");
      handleSnackbarOpen("종료 단계로 변경되었습니다.");
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
    },
  });

  const {
    mutate: moveTrelloStatus,
    ResponseHandler: ResponseHandlerOfMoveTrelloStatus,
  } = TRELLO_BID_QUERY.useMoveTrelloStatus({
    bidId: trelloDetail.id,
    onSuccess: () => {
      handleSnackbarOpen("운송 상태가 변경되었습니다.");
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
    },
  });

  const {
    mutate: moveExportTrelloStatus,
    ResponseHandler: ResponseHandlerOfMoveExportTrelloStatus,
  } = TRELLO_BID_QUERY.useMoveExportTrelloStatus({
    bidId: trelloDetail.id,
    onSuccess: () => {
      handleSnackbarOpen("운송 상태가 변경되었습니다.");
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
    },
  });

  const isRequiredStatus =
    trelloDetail.projectStatus === "portEntryAndPrepareCustoms" ||
    (trelloDetail.projectStatus === "payment" && trelloDetail.isImport) ||
    trelloDetail.projectStatus === "completeCustoms" ||
    trelloDetail.projectStatus === "delivering";

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const history = useHistory();

  const { data: linerData } = ADMIN_COMMON_QUERY.useGetLinerList();

  /** 수입 트렐로 상세 > 상태 강제 변경 가능한 의뢰 상태 */
  const isScheduleStatus =
    trelloDetail.projectStatus === "scheduling" ||
    trelloDetail.projectStatus === "containerCarryOut" ||
    trelloDetail.projectStatus === "containerPickup" ||
    trelloDetail.projectStatus === "gateIn" ||
    trelloDetail.projectStatus === "loaded";

  /** 수출 트렐로 상세 > 의뢰 상태가 종료인 경우 드랍다운이 아닌 '종료' 텍스트를 노출 */
  const isFinishedStatus = trelloDetail.projectStatus === "finished";

  const handleProjectStatusChange = (e: SelectChangeEvent) => {
    if (
      e.target.value === "moving" &&
      !checkIsBlNumberByBLType(trelloDetail.management)
    ) {
      handleSnackbarOpen(
        "BL(AWB)를 입력완료 해야 상태값 변경이 가능합니다.",
        "warning"
      );
      return;
    }
    moveTrelloStatus({
      status: e.target.value as Extract<
        BidProjectStatus,
        "containerCarryOut" | "containerPickup" | "gateIn" | "loaded" | "moving"
      >,
    });
  };

  const handleExportProjectStatusChange = (e: SelectChangeEvent) => {
    //TODO: 수출에서는 BL확인이 다를 수 있음 동일하다면 공용함수로 뺄 예정
    if (
      e.target.value === "moving" &&
      !checkIsBlNumberByBLType(trelloDetail.management)
    ) {
      handleSnackbarOpen(
        "BL(AWB)를 입력완료 해야 상태값 변경이 가능합니다.",
        "warning"
      );
      return;
    }

    if (e.target.value === "finished") {
      setShowsConfirmModal(true);
      return;
    }
    moveExportTrelloStatus({
      status: e.target.value as BidProjectStatus,
    });
  };

  return (
    <Box>
      <Box>
        <Button
          onClick={() => {
            setTrelloCardId(trelloDetail.id);
            history.push(
              trelloDetail.team.isPrivate
                ? `/users/list/${trelloDetail.userId}`
                : `/team/${trelloDetail.teamId}`
            );
          }}
          style={{ marginRight: 8, cursor: "pointer" }}
        >
          {trelloDetail.team.isPrivate
            ? trelloDetail.user.company
            : trelloDetail.team.company}
        </Button>

        <Button
          onClick={() => {
            setTrelloCardId(trelloDetail.id);
            history.push(`/bid/detail/${trelloDetail.id}`);
          }}
        >
          BID ID:{trelloDetail.id}
        </Button>

        {checkIsSettlement(trelloDetail.projectStatus) &&
          APP_NAME === "shipda-admin" &&
          currentAdminAuthInfo?.authority !== "finance" && (
            <Button
              variant="outlined"
              sx={{ marginLeft: "16px" }}
              onClick={() => setShowRejectSettlementModal(true)}
              color="error"
            >
              반려 요청
            </Button>
          )}
      </Box>

      {APP_NAME === "shipda-admin" && !!trelloDetail.poMapping?.length && (
        <Typography>
          PO No.:{" "}
          {trelloDetail.poMapping.map((v) => {
            if (!v.purchaseOrder) return null;

            return (
              <Box
                key={v.poId}
                sx={{ display: "inline-flex", marginRight: "10px" }}
              >
                <span>{v.purchaseOrder.poNumber}</span>

                <OpenInNewIcon
                  onClick={() => {
                    setTrelloCardId(trelloDetail.id);
                    history.push(`/order/${v.poId}`);
                  }}
                  sx={{ cursor: "pointer", color: blue["500"] }}
                />
              </Box>
            );
          })}
        </Typography>
      )}

      <Box display={"flex"} pb={1}>
        <ChangeStatus
          isScheduleStatus={isScheduleStatus}
          isFinishedStatus={isFinishedStatus}
          trelloDetail={trelloDetail}
          handleExportProjectStatusChange={handleExportProjectStatusChange}
          handleProjectStatusChange={handleProjectStatusChange}
        />

        {isRequiredStatus && APP_NAME === "shipda-admin" && (
          <Button
            variant="contained"
            color="error"
            sx={{ marginLeft: 5 }}
            size="small"
            onClick={() => setShowsConfirmModal(true)}
          >
            강제 완료 변경
          </Button>
        )}
      </Box>

      <Box>
        {linerData && (
          <UpdateBLNumberAndLiner
            freightType={trelloDetail.freightType}
            management={trelloDetail.management}
            projectStatus={trelloDetail.projectStatus}
            linerData={linerData}
            BLType={trelloDetail.opCheckPoint?.typeBL}
          />
        )}
      </Box>

      <Grid sx={{ ml: 1 }}>
        <ArrivalNoticeSubmit
          isImport={trelloDetail.isImport}
          shipmentId={trelloDetail.id}
          freightType={trelloDetail.freightType}
          shipmentStatus={trelloDetail.projectStatus}
        />
      </Grid>

      <BasicDescription trelloDetail={trelloDetail} partnerList={partnerList} />

      {showRejectSettlementModal && (
        <RejectSettlementModal
          setShowRejectSettlementModal={setShowRejectSettlementModal}
          showRejectSettlementModal={showRejectSettlementModal}
          bidId={trelloDetail.id}
        />
      )}

      {showsConfirmModal && APP_NAME === "shipda-admin" && (
        <MoveToFinishedStatusConfirmModal
          showsConfirmModal={showsConfirmModal}
          setShowsConfirmModal={setShowsConfirmModal}
          confirmMessage={confirmMessage}
          setConfirmMessage={setConfirmMessage}
          moveTrelloToFinishedStatus={
            trelloDetail.isImport
              ? moveTrelloToFinishedStatus
              : moveExportShipmentToFinished
          }
        />
      )}

      {ResponseHandlerOfMoveTrelloToFinishedStatus}
      {ResponseHandlerOfMoveTrelloStatus}
      {ResponseHandlerOfMoveExportTrelloStatus}
      {ResponseHandlerOfMoveExportShipmentToFinished}
    </Box>
  );
};

export default BasicInfo;
